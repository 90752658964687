<template>
    <div id="sc-new-product">
        <b-button class="sc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h1>New Product</h1>
        <hr />
        <b-form @submit="onSubmitNewProduct">
            <b-form-group id="sc-name-container" label="Product Name">
                <b-form-input v-model="form.name" required placeholder="Enter product name"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-type-container" label="Product Type">
                <b-dropdown id="sc-dropdown-type" text="Select Type" class="m-md-2">
                    <b-dropdown-item v-for="item in productTypes" :key="item.id" @click="onSelectDropdown(item.id)">
                        {{ item.name }}
                    </b-dropdown-item>
                </b-dropdown>
                <div id="sc-dropdown-value">{{ form.itemType }}</div>
            </b-form-group>

            <b-form-group id="sc-sku-container" label="SKU">
                <b-form-input v-model="form.sku" type="text" placeholder="Enter SKU"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-price-container" label="Price">
                <b-form-input v-model="form.price" type="text" placeholder="Enter price"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-quickbooksid-container" label="Quickbooks Id">
                <b-form-input v-model="form.quickbooksId" type="text" placeholder="Enter Quickbooks id"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-description-container" label="Description">
                <b-form-input v-model="form.description" placeholder="Enter description"></b-form-input>
            </b-form-group>

            <b-button class="sc-button-primary" type="submit" variant="primary">Add</b-button>
        </b-form>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "NewProduct",
    data() {
        return {
            form: {
                itemType: 1,
            },
            productTypes: [],
        };
    },
    methods: {
        onSubmitNewProduct(event) {
            event.preventDefault();
            console.log(this.form);
            this.createNewProduct();
        },
        onSelectDropdown(id) {
            this.form.itemType = id;
        },
        async createNewProduct() {
            let response = await APICalls.createProduct(this.form);
            console.log(response);
            this.$toasted.show("New product created", { position: "bottom-center", className: "sc-toast-success", duration: 6000 });
            this.form = {};
            this.$router.back();
        },
        async getProductTypes() {
            let productTypes = await APICalls.getProductTypes();
            this.productTypes = productTypes;
        },
    },
    mounted() {
        this.getProductTypes();
    },
};
</script>

<style lang="scss">
#sc-new-product {
    form {
        margin-top: 20px;

        .col-form-label {
            font-size: 20px;
        }

        #sc-name-container,
        #sc-sku-container,
        #sc-type-container,
        #sc-price-container,
        #sc-quickbooksid-container {
            display: inline-block;
            width: 20%;
        }

        #sc-sku-container,
        #sc-type-container,
        #sc-price-container,
        #sc-quickbooksid-container {
            padding-left: 25px;
        }

        #sc-type-container {
            #sc-dropdown-type {
                display: inline-flex;
                width: 90%;
                margin: 0px !important;
            }

            #sc-dropdown-value {
                width: 10%;
                display: inline-flex;
                justify-content: flex-end;
            }
        }

        #sc-description-container {
            display: inline-block;
            width: 50%;
        }

        .sc-button-primary {
            display: block;
            width: 180px;
            margin-top: 10px;
        }
    }
}
</style>
